import WithLoadable from "@components/Loadable";
import appConfig from "@libs/common/app-config.json";

export const routePath = {
  dashBoard: "/app/main",
  login: "/app/login",
  forgotPassword: "/app/forgot-password",
  verifyCode: "/app/verify-code",
  newPassword: "/app/new-password",
  register: "/app/register",
  athlete: "/app/athletes",
  detailAthlete: "/app/athlete/:id",
  highSchool: "/app/schools",
  highSchoolDetail: "/data/school/:id",
  colleges: "/app/colleges",
  collegeDetail: "/app/college/:id",
  userManagement: "/app/users",
  messenger: "/app/messenger",
  posts: "/app/posts",
  smsDispatcher: "/app/send-sms",
  duplicateAthletes: "/app/duplicate-athletes",
  notificationDispatcher: "/app/send-notification",
  schoolAthlete: "/app/school/:schoolId/athletes",
  recommendedConnections: "/app/athlete-recommends",
  subscriptions: "/app/subscriptions",
  highSchoolCreate: "/data/school/create",
};

const Routes = [
  {
    name: "Dashboard",
    component: WithLoadable(() => import("@containers/HomePage")),
    path: routePath.dashBoard,
    permissions: [],
    authRequired: true,
    isVisible: true,
    icon: "dashboard",
  },
  {
    name: "Login",
    component: WithLoadable(() => import("@containers/LoginPage")),
    path: routePath.login,
    authRequired: false,
    permissions: null,
    isVisible: false,
    icon: null,
  },
  {
    name: "Forgot Password",
    component: WithLoadable(() => import("@containers/ForgotPassword")),
    path: routePath.forgotPassword,
    permissions: null,
    authRequired: false,
    isVisible: false,
  },
  {
    name: "Athletes",
    component: WithLoadable(() => import("@containers/AthletePage")),
    path: routePath.athlete,
    permissions: appConfig.roles.default,
    isVisible: true,
    authRequired: true,
    icon: "idcard",
  },
  {
    name: "Detail Athlete",
    component: WithLoadable(() => import("@containers/AthleteDetailPage")),
    path: routePath.detailAthlete,
    permissions: null,
    isVisible: false,
    authRequired: true,
    icon: null,
  },
  {
    name: "High Schools",
    component: WithLoadable(() => import("@containers/SchoolPage")),
    path: routePath.highSchool,
    authRequired: true,
    permissions: appConfig.roles.default,
    isVisible: true,
    icon: "home",
  },
  {
    name: "High School Detail",
    component: WithLoadable(() => import("@containers/SchoolDetailPage")),
    path: routePath.highSchoolDetail,
    authRequired: true,
    permissions: appConfig.roles.default,
    isVisible: false,
    icon: null,
  },
  {
    name: null,
    component: WithLoadable(() => import("@containers/SchoolDetailPage")),
    path: routePath.highSchoolCreate,
    authRequired: true,
    permissions: appConfig.roles.default,
    isVisible: false,
    icon: null,
  },
  {
    name: "Colleges",
    component: WithLoadable(() => import("@containers/CollegePage")),
    path: routePath.colleges,
    authRequired: true,
    permissions: null,
    isVisible: true,
    icon: "home",
  },
  {
    name: "Users",
    component: WithLoadable(() => import("@containers/UserPage")),
    path: routePath.userManagement,
    authRequired: true,
    permissions: null,
    isVisible: true,
    icon: "user",
  },
  // {
  //   name: "Feeds",
  //   component: WithLoadable(() => import("@containers/PostManagement")),
  //   path: routePath.posts,
  //   permissions: null,
  //   authRequired: true,
  //   isVisible: true,
  //   icon: "database",
  // },
  // {
  //   name: "Sms Tool",
  //   component: WithLoadable(() => import("@containers/SmsDispatcher")),
  //   path: routePath.smsDispatcher,
  //   permissions: null,
  //   authRequired: true,
  //   isVisible: true,
  //   icon: "message",
  // },
  {
    name: "Duplicate Athletes",
    component: WithLoadable(() => import("@containers/DuplicateAthletes")),
    path: routePath.duplicateAthletes,
    permissions: null,
    authRequired: true,
    isVisible: true,
    icon: "database",
  },
  {
    name: "Global Notification",
    component: WithLoadable(() => import("@containers/NotificationDispatcher")),
    path: routePath.notificationDispatcher,
    permissions: null,
    authRequired: true,
    isVisible: true,
    icon: "notification",
  },
  {
    name: "Athletes",
    component: WithLoadable(() => import("@containers/SchoolAthlete")),
    path: routePath.schoolAthlete,
    permissions: null,
    authRequired: true,
    isVisible: false,
    icon: "idcard",
  },
  {
    name: "Recommends",
    component: WithLoadable(() => import("@containers/Recommends")),
    path: routePath.recommendedConnections,
    permissions: null,
    authRequired: true,
    isVisible: false,
    icon: null,
  },
  {
    name: "Subscriptions",
    component: WithLoadable(() => import("@containers/SubscriptionPage")),
    path: routePath.subscriptions,
    permissions: ["admin"],
    authRequired: true,
    isVisible: true,
    icon: "contacts",
  },
  {
    name: "Verify Code",
    component: WithLoadable(() =>
      import("@containers/ForgotPassword/VerifyCode")
    ),
    path: routePath.verifyCode,
    permissions: null,
    authRequired: false,
    isVisible: false,
    icon: null,
  },
  {
    name: "New Password",
    component: WithLoadable(() =>
      import("@containers/ForgotPassword/NewPassword")
    ),
    path: routePath.newPassword,
    permissions: null,
    authRequired: false,
    isVisible: false,
    icon: null,
  },
];

export default Routes;
